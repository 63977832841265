import { useGetDevicesQuery } from '../../../../../../../redux/services/DevicesApi'
import { Loading } from '../../../../../../../components/Loading/Loading'
import React, { useState } from 'react'
import { Blocks } from '../../../../../../../components/Blocks/Blocks'
import Status from '../components/Status/Status'
import Actions from '../components/Actions/Actions'
import styles from './SettingsBlocks.module.css'
import { Pagination } from '../../../../../../../components/Pagination/Pagination'

const SettingsBlocks = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, ] = useState<number>(7)
  const { data: devices, isLoading: isDevicesLoading } = useGetDevicesQuery(
    { page, page_size: pageSize },
    { refetchOnMountOrArgChange: true, },
  )
  if (isDevicesLoading) {
    return <Loading />
  }
  return (
    <>
      <Blocks
        blocks={
          devices?.results?.map((device, index) => {
            return (
              <div className={'block'} key={index}>
                <div className={styles.component}>
                  <div className={styles.info}>
                    <div className={styles.name}>
                      <Status status={device.is_online} /> {device.name}
                    </div>
                    <p className={styles.model}>{device.model}</p>
                    <p className={styles.date}>{device.create_dt}</p>
                  </div>
                  <div>
                    <Actions deviceId={device.id} />
                  </div>
                </div>
                <div className={styles.component}>
                  <div className={styles.floorBlock}>
                    <p className={styles.title}>ID устройства:</p>
                    {device.id}
                  </div>
                  <div className={styles.floorBlock} style={{ textAlign: 'right' }}>
                    <p className={styles.title}>Версия приложения:</p>
                    {device.app_version}
                  </div>
                </div>
              </div>
            )
          }) || []
        }
      />
      <Pagination
        totalPages={Math.ceil((devices?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={devices?.next || page}
        previousPage={devices?.previous || page}
      />
    </>
  )
}

export default SettingsBlocks
