import styles from './MainContainerWithWindow.module.css'
import React, { type ReactNode } from 'react'

interface TMainContainerProps {
  children: ReactNode
}

const MainContainerWithWindow = (props: TMainContainerProps) => {
  const { children } = props
  return (
    <div className={styles.background}>
      <div className={styles.container}>{children}</div>
    </div>
  )
}

export default MainContainerWithWindow
