import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import type IBaseRequest from '../types/Base/BaseRequestInterface'
import { type TResponseGetRequest } from '../types/PaymentsTypes'

export const PaymentsApi = createApi({
  reducerPath: 'PaymentsApi',
  tagTypes: ['Payments'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl + '/trader_payments',
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getPayments: build.query<TResponseGetRequest, IBaseRequest>({
      query: (params) => ({
        url: '/',
        params: { page: params?.page || 1, page_size: params?.page_size || 0 },
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetPaymentsQuery } = PaymentsApi
