import { useRecoilState } from 'recoil'
import { defaultIsMobile } from '../../../../../utils/states/isMobile.state'
import React from 'react'
import { PaymentsBlocks } from './components/Blocks/PaymentsBlocks'
import { PaymentsTable } from './components/Table/PaymentsTable'

export const Content = () => {
  const [version] = useRecoilState(defaultIsMobile)
  return <div>{version.isMobile ? <PaymentsBlocks /> : <PaymentsTable />}</div>
}
