import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import type IBaseRequest from '../types/Base/BaseRequestInterface'
import {
  type IDeviceCreateRequest,
  type IDeviceDeactivateRequest,
  type IDevicesGetResponse,
  type IDevicesResponse,
} from '../types/DevicesTypes'

export const DevicesApi = createApi({
  reducerPath: 'DevicesApi',
  tagTypes: ['Devices'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendUrl}/devices/`,
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getDevices: build.query<IDevicesGetResponse, IBaseRequest>({
      query: (params) => ({
        url: '/',
        method: 'GET',
        params: { page: params?.page || 1, page_size: params?.page_size || 0 },
      }),
      providesTags: [{ type: 'Devices', id: 'LIST' }],
    }),
    getDevice: build.query<IDevicesResponse, string>({
      query: (id: string) => ({
        url: `${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Devices', id: 'LIST' }],
    }),
    getQr: build.query<any, string>({
      query: (deviceId: string) => ({
        url: `/get_token_qr/${deviceId}/`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          return window.URL.createObjectURL(blob)
        },
      }),
      providesTags: [{ type: 'Devices', id: 'LIST' }],
    }),
    createDevice: build.mutation<IDevicesGetResponse, IDeviceCreateRequest>({
      query: (body: IDeviceCreateRequest) => ({
        url: '/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Devices', id: 'LIST' }],
    }),
    deactivateDevice: build.mutation<IDevicesResponse, IDeviceDeactivateRequest>({
      query: (body: IDeviceDeactivateRequest) => ({
        url: `/deactivate/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Devices', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useGetQrQuery,
  useCreateDeviceMutation,
  useDeactivateDeviceMutation,
} = DevicesApi
