import { useDeleteCardMutation } from '../../../../../../../../redux/services/CardsApi'
import { Button } from '../../../../../../../../components/Button/Button'
import { ModalDefault } from '../../../../../../../../components/ModalDefault/ModalDefault'
import React from 'react'

interface TDeleteCardModalProps {
  id: number
  modalActive: boolean
  setModalActive: (status: boolean) => void
}

const DeleteCardModal = (props: TDeleteCardModalProps) => {
  const { id, modalActive, setModalActive } = props
  const [deleteCard] = useDeleteCardMutation()
  const handleDelete = async () => {
    await deleteCard(id).unwrap().catch()
  }
  return (
    <ModalDefault
      title={'Удалить карту?'}
      text={'После удаления карта будет недоступна.'}
      titleMargin={'8px'}
      textMargin={'24px'}
      active={modalActive}
      setActive={setModalActive}
    >
      <Button
        text={'Отмена'}
        filled={false}
        style={{ marginRight: '8px' }}
        action={() => {
          setModalActive(false)
        }}
      />
      <Button
        text={'Удалить'}
        action={() => {
          setModalActive(false)
          handleDelete()
        }}
      />
    </ModalDefault>
  )
}

export default DeleteCardModal
