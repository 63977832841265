import styles from './AccountSettings.module.css'
import React, { useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { useGetSelfQuery } from '../../../redux/services/TraderApi'
import { Loading } from '../../../components/Loading/Loading'
import CreateToken from './components/modals/CreateToken/CreateToken'
import SettingsContent from './components/SettingsContent/SettingsContent'
import { MainContainer } from '../../../components/MainContainer/MainContainer'

export const AccountSettings = () => {
  const { data: trader, isLoading } = useGetSelfQuery()
  const [active, setActive] = useState<boolean>(false)
  if (isLoading) {
    return <Loading />
  }
  return (
    <MainContainer>
      <div className={'cards'} style={{ height: '100%', position: 'relative' }}>
        <div className={styles.settingsWrapper}>
          <Button
            text={'Создать токен'}
            style={{ position: 'absolute', right: '24px', width: '133px' }}
            action={() => {
              setActive(true)
            }}
          />
          <h1>Настройки</h1>
          <div className={styles.settingsInfo}>
            <p>Аккаунт:</p>
            <b>{trader?.username || ''}</b>
          </div>
          <SettingsContent />
          <CreateToken active={active} setActive={setActive} />
        </div>
      </div>
    </MainContainer>
  )
}
