import { recoilPersist } from 'recoil-persist'
import { atom } from 'recoil'

const { persistAtom: persistActiveOrdersFilter } = recoilPersist({
  key: 'activeOrdersFilter',
  storage: localStorage,
  converter: JSON,
})

const { persistAtom: persistActiveWithdrawalsFilter } = recoilPersist({
  key: 'activeWithdrawalsFilter',
  storage: localStorage,
  converter: JSON,
})

export const defaultActiveOrdersFilter = atom({
  key: 'activeOrdersFilter',
  default: {
    activeOrdersFilter: '',
  },
  effects_UNSTABLE: [persistActiveOrdersFilter],
})

export const defaultActiveWithdrawalsFilter = atom({
  key: 'activeWithdrawalsFilter',
  default: {
    activeWithdrawalsFilter: '',
  },
  effects_UNSTABLE: [persistActiveWithdrawalsFilter],
})

