import React, { useCallback, useRef } from 'react'
import styles from './Selector.module.css'
import { useOnCloseOutside } from './hooks/useOnCloseOutside';
import { IOrdersSelectorProps } from './types';

export const Selector = (props: IOrdersSelectorProps) => {
  const {
    id,
    status,
    statusId,
    handleEdit,
    needRecalculation = false,
    setOpen,
    open,
    pageName
  } = props;
  const selectorRef = useRef<HTMLDivElement>(null);

  const handleSwitch = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(prevId => prevId === id ? null : id);
  }, [id, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(null);
  }, [setOpen]);

  const isOpen = open === id;

  useOnCloseOutside(selectorRef, handleClose, 'mousedown', isOpen);

  if (statusId !== 'BN' && statusId !== 'ID') {
    return (
      <div className={styles.wrapper}>
        <div className={`${styles.head} ${styles.none}`}>
          <span>{status}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper} ref={selectorRef}>
      <div className={`${styles.head} ${styles.select_active}`} onClick={handleSwitch}>
        {status}
        <img
          src={isOpen ? '/arrow_up_icon.png' : '/arrow_down_icon.png'}
          alt={'arrow icon'}
        />
      </div>
      {isOpen && (
        <ul id={id} onClick={(e) => e.stopPropagation()}>
          <li
            onClick={(event) => {
              event.stopPropagation();
              handleEdit(statusId === 'ID' ? 'FR' : 'SF', id);
              handleClose();
            }}
          >
            Успешно завершенные
          </li>
          <li
            onClick={(event) => {
              event.stopPropagation();
              handleEdit(pageName === 'withdraw' ? 'CANCELED' : 'CR', id);
              handleClose();
            }}
          >
            Отказ
          </li>
          {needRecalculation && statusId !== 'ID' && (
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleEdit('IP', id);
                handleClose();
              }}
            >
              Заявка с перерасчетом
            </li>
          )}
        </ul>
      )}
    </div>
  );
};