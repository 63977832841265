import React, { type ReactNode } from 'react'
import './RequisiteContainer.css'
import { Header } from '../Header/Header'
import { Navbar } from '../Navbar/Navbar'
import { useRecoilState } from 'recoil'
import { defaultIsMobile } from '../../utils/states/isMobile.state'

interface TMainContainerProps {
  children: ReactNode
  style?: React.CSSProperties
}

const RequisiteContainer = (props: TMainContainerProps) => {
  const { children, style } = props
  const [version] = useRecoilState(defaultIsMobile)
  const isMobile = version.isMobile
  return (
    <div className={'cardsContainer'}>
      {isMobile ? <Header /> : <Navbar />}
      <div
        style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative' }}
      >
        <div className={'cardsWrapper'} style={style}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default RequisiteContainer
