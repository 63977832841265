import React from 'react'
import { ErrorMessagePage } from '../../../components/Error/ErrorMessagePage'
import MainContainerWithWindow from '../../../components/MainContainerWithWindow/MainContainerWithWindow'

export const ServerError = () => {
  return (
    <MainContainerWithWindow>
      <ErrorMessagePage errorCode={'500'} errorText={'Ошибка на сервере'} />
    </MainContainerWithWindow>
  )
}
