import React from 'react'
import { Header } from '../components/Header/Header'
import { MainContainer } from '../../../../components/MainContainer/MainContainer'
import { Content } from '../components/Content/Content'

export const Payments = () => {
  return (
    <MainContainer needLogo={false}>
      <div className={'overflow'} style={{ height: '100%', position: 'relative' }}>
        <Header />
        <Content />
      </div>
    </MainContainer>
  )
}
