import { TFilterItem } from '../../pages/Trader/OrdersAndWithdrawals/components/Filter/types'

export const ordersFilters : TFilterItem[] = [
  {
    filterId : '',
    filterText : 'Все'
  },
  {
    filterId : 'BN',
    filterText : 'Новые заявки'
  },
  {
    filterId : 'SF,AA',
    filterText : 'Успешно завершенные'
  },
  {
    filterId : 'ID',
    filterText : 'Спор'
  },
  {
    filterId : 'FR',
    filterText : 'Завершенные с перерасчетом'
  },
  {
    filterId : 'UR',
    filterText : 'Заявка с перерасчетом'
  },
  {
    filterId : 'TO',
    filterText : 'Просроченные'
  },
  {
    filterId : 'IP',
    filterText : 'В процессе'
  },
  {
    filterId : 'CR',
    filterText : 'Перерасчет отклонен'
  },
  {
    filterId : 'AAD',
    filterText : 'Автоматически одобрена из диспута'
  }
]
export const withdrawalsFilters : TFilterItem[] = [
  {
    filterId : '',
    filterText : 'Все'
  },
  {
    filterId : 'BN',
    filterText : 'Новая заявка'
  },
  {
    filterId : 'SF,AA',
    filterText : 'Успешно завершенные'
  },
  {
    filterId : 'TO',
    filterText : 'Просроченные'
  },

]