import { type Middleware, type MiddlewareAPI, isRejected } from '@reduxjs/toolkit'
import { FORBIDDEN_ERROR, SERVER_ERROR } from '../utils/constants/routes'
export const errorCatcherMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejected(action)) {
    if (action.payload?.status === 403) {
      window.location.href = FORBIDDEN_ERROR
    }
    if (action.payload?.status === 500) {
      window.location.href = SERVER_ERROR
    }
  }
  return next(action)
}
