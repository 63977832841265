import React from 'react';

import Select from 'react-select';
import {useGetAllCardsQuery} from "../../../../../../redux/services/CardsApi";
import {Loading} from "../../../../../../components/Loading/Loading";

export const MultiSelectCards = (props: any) => {
  const { setCardsCreate } = props
  const { data, isLoading } = useGetAllCardsQuery({})
  const transformedCards = data && data.results.map(item => ({
    value: item.tag,
    label: item.tag
  }));

  const handleChange = (selectedOptions: any) => {
    const selectedCards = selectedOptions ? selectedOptions.map((option:any) => option.value) : [];
    setCardsCreate(selectedCards);
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: '2px solid #dbe0f2',
      borderRadius: '4px',
      boxShadow: 'none',
      '&:hover': {
        border: '2px solid #dbe0f2'
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: '14px',
      color: '#8e95b0'
    })
  };


  if (isLoading) return <Loading />

  return (
      <div style={{marginBottom: '16px', width: '100%'}}>

        <div style={{display: 'flex', gap: '4px'}}>
          <span style={{color: 'red'}}>*</span>
          <div style={{marginBottom: '4px', fontSize: '14px', fontWeight: '400', color: 'black'}}>Название реквизита</div>
        </div>

        <Select
            isMulti={true}
            options={transformedCards}
            styles={customStyles}
            onChange={handleChange}
            placeholder={'Введите номер реквизита'}
        />
      </div>
  )
}