import { type TActionsProps } from './types'
import styles from './Actions.module.css'
import React, { useState } from 'react'
import { useDeactivateDeviceMutation } from '../../../../../../../../redux/services/DevicesApi'
import { Loading } from '../../../../../../../../components/Loading/Loading'
import { ModalDefault } from '../../../../../../../../components/ModalDefault/ModalDefault'
import { Button } from '../../../../../../../../components/Button/Button'
import ModalQr from './ModalQr'

const Actions = (props: TActionsProps) => {
  const { deviceId } = props
  const [tokenActive, setTokenActive] = useState<boolean>(false)
  const [offActive, setOffActive] = useState<boolean>(false)
  const [deactivate, { isLoading: isDeactivateLoading }] = useDeactivateDeviceMutation()
  const handleDeactivateDevice = async () => {
    await deactivate({ id: deviceId })
      .then(() => {
        setOffActive(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  if (isDeactivateLoading) {
    return <Loading />
  }
  return (
    <>
      <div className={styles.actions}>
        <span
          style={{ color: '#1890FF' }}
          onClick={() => {
            setTokenActive(true)
          }}
        >
          Показать токен
        </span>
        <span
          style={{ color: '#FE5B37' }}
          onClick={() => {
            setOffActive(true)
          }}
        >
          Удалить устройство
        </span>
      </div>
      {tokenActive && (
        <ModalQr active={tokenActive} deviceId={deviceId} setActive={setTokenActive} />
      )}
      <ModalDefault
        title={'Удалить устройство из вашего аккаунта?'}
        text={'После удаления, использование приложения с данного устройства будет невозможно.'}
        titleMargin={'8px'}
        textMargin={'24px'}
        active={offActive}
        setActive={setOffActive}
      >
        <Button
          text={'Отмена'}
          filled={false}
          style={{ marginRight: '8px' }}
          action={() => {
            setOffActive(false)
          }}
        />
        <Button
          text={'Отключить'}
          action={async () => {
            await handleDeactivateDevice()
          }}
        />
      </ModalDefault>
    </>
  )
}
export default Actions
