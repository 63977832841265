import React, { useState } from 'react'
import styles from './CardsSwitcher.module.css'
import { useToggleAllCardsMutation } from '../../../../../redux/services/CardsApi'
import { Loading } from '../../../../../components/Loading/Loading'
import { ModalDefault } from '../../../../../components/ModalDefault/ModalDefault'
import { Button } from '../../../../../components/Button/Button'

export const CardsSwitcher = () => {
  const [on, setOn] = useState<boolean | null>(null)
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [toggleCards, { isLoading }] = useToggleAllCardsMutation(undefined)
  const handleToggle = async (status: boolean) => {
    await toggleCards({ active: status })
      .unwrap()
      .then(() => {
        setOn(status)
        setModalActive(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <p className={styles.switch_cards}>
      <input
        className={
          on === false ? `${styles.switch_cards_off} ${styles.active}` : styles.switch_cards_off
        }
        type='button'
        onClick={() => {
          setModalActive(true)
        }}
        value={'Выкл. все'}
      />
      <input
        className={
          on === true ? `${styles.switch_cards_on} ${styles.active}` : styles.switch_cards_on
        }
        type='button'
        onClick={async () => {
          await handleToggle(true)
        }}
        value={'Вкл. все'}
      />
      <ModalDefault
        title={'Выключить карты?'}
        titleMargin={'8px'}
        text={'Все карты станут неактивными и вы не будете получать заявки.'}
        textMargin={'24px'}
        active={modalActive}
        setActive={setModalActive}
      >
        <Button
          action={async () => {
            await handleToggle(false)
          }}
          text={'Выключить'}
          style={{ marginRight: '8px' }}
        />
        <Button
          action={() => {
            setModalActive(false)
          }}
          text={'Отмена'}
          filled={false}
        />
      </ModalDefault>
    </p>
  )
}
