import styles from './Status.module.css'
import { type TStatusProps } from './types'

const Status = (props: TStatusProps) => {
  const { status } = props
  const formatStatus = (status: boolean) => {
    return !status ? 'offline' : 'online'
  }
  return (
    <div className={styles.status}>
      <span className={!status  ? styles.offline : styles.online}>
        {formatStatus(status)}
      </span>
    </div>
  )
}

export default Status
