import styles from './Info.module.css'
export const TimeArea = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.title}>Часовой пояс:</div>
        <div className={styles.value}>GMT +3</div>
      </div>
    </div>
  )
}
