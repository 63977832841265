import styles from './Income.module.css'
import { type OrderModel } from '../../../../../utils/functions/map/mapOrders'
import React, { useState } from 'react'
import { ModalDefault } from '../../../../../components/ModalDefault/ModalDefault'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'
import { parseStringToNumber } from '../../../../../utils/functions/parseNumber'
import { useUpdateOrderDataMutation } from '../../../../../redux/services/OrdersApi'
import { ControlledInput } from '../../../../../components/ControlledInput/ControlledInput'
import { Button } from '../../../../../components/Button/Button'

export interface TIncomeProps {
  order: OrderModel
}

interface TInputs {
  income: string
}
const Income = (props: TIncomeProps) => {
  const { order } = props
  const formMethods = useForm<TInputs>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const { handleSubmit, setError, reset } = formMethods
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [updateOrder] = useUpdateOrderDataMutation(undefined)

  const onSubmit: SubmitHandler<TInputs> = async (data: any) => {
      const body: any = {
        status: order.statusId === 'ID' ? 'FR' : 'UR',
        temp_income: parseStringToNumber(data.income),
      }
      await updateOrder({ body, id: order.id }).then(() => {
        setModalActive(false)
        reset()
      })
  }
  if (order.statusId === 'IP' || order.statusId === 'ID') {
    return (
      <FormProvider {...formMethods}>
        <p
          className={styles.button}
          onClick={() => {
            setModalActive(true)
          }}
        >
          Пересчитать
        </p>
        <ModalDefault
          title={'Пересчитать'}
          active={modalActive}
          setActive={setModalActive}
          titleMargin={'24px'}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ControlledInput
              title={'Пришло'}
              placeholder={'Введите сумму'}
              name={'income'}
              type={'text'}
              rules={{ required: true, maxLength: 13 }}
              blockStyle={{ marginBottom: '16px' }}
              transform={(text: string | undefined) => {
                if (text) {
                  return text
                    .replace(/[^0-9]/g, '')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
                    .slice(0, 13)
                }
                return text
              }}
            />
            <Button text={'Отправить'} type={'submit'} disabled={false} />
          </form>
        </ModalDefault>
      </FormProvider>
    )
  }
  if (order.statusId === 'UR') {
    return (
      <p className={styles.notOk}>
        {order.temp_income}
        <span>Не подтвержден</span>
      </p>
    )
  }
  if (order.statusId === 'FR') {
    return (
      <p className={styles.ok}>
        {order.real_income}
        <span>Одобрено</span>
      </p>
    )
  }
  if (order.statusId === 'CR') {
    return (
      <p className={styles.declined}>
        {order.real_income}
        <span>Отклонено</span>
      </p>
    )
  }
  return <>{order.real_income}</>
}

export default Income
