import React from 'react'
import { ErrorMessagePage } from '../../../components/Error/ErrorMessagePage'
import MainContainerWithWindow from '../../../components/MainContainerWithWindow/MainContainerWithWindow'

export const NotFoundError = () => {
  return (
    <MainContainerWithWindow>
      <ErrorMessagePage errorCode={'404'} errorText={'Страница не найдена'} />
    </MainContainerWithWindow>
  )
}
