import { configureStore } from '@reduxjs/toolkit'
import { CardsApi } from './services/CardsApi'
import { TraderApi } from './services/TraderApi'
import { PaymentsApi } from './services/PaymentsApi'
import { OrdersApi } from './services/OrdersApi'
import { BanksApi } from './services/BanksApi'
import { AuthApi } from './services/AuthApi'
import { DevicesApi } from './services/DevicesApi'
import { errorCatcherMiddleware } from './middleware'
import { WithdrawalsApi } from './services/WithdrawalsApi'
import { DisputesApi } from "./services/DisputesApi";

export const store = configureStore({
  reducer: {
    [CardsApi.reducerPath]: CardsApi.reducer,
    [TraderApi.reducerPath]: TraderApi.reducer,
    [PaymentsApi.reducerPath]: PaymentsApi.reducer,
    [OrdersApi.reducerPath]: OrdersApi.reducer,
    [WithdrawalsApi.reducerPath]: WithdrawalsApi.reducer,
    [BanksApi.reducerPath]: BanksApi.reducer,
    [AuthApi.reducerPath]: AuthApi.reducer,
    [DevicesApi.reducerPath]: DevicesApi.reducer,
    [DisputesApi.reducerPath]: DisputesApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(errorCatcherMiddleware)
      .concat(CardsApi.middleware)
      .concat(TraderApi.middleware)
      .concat(PaymentsApi.middleware)
      .concat(OrdersApi.middleware)
      .concat(WithdrawalsApi.middleware)
      .concat(BanksApi.middleware)
      .concat(AuthApi.middleware)
      .concat(DevicesApi.middleware)
      .concat(DisputesApi.middleware)
})
