import { ControlledInput } from '../../../../../../components/ControlledInput/ControlledInput'
import { Button } from '../../../../../../components/Button/Button'
import { ModalDefault } from '../../../../../../components/ModalDefault/ModalDefault'
import React, { useState } from 'react'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'
import { useCreateDeviceMutation } from '../../../../../../redux/services/DevicesApi'
import { Loading } from '../../../../../../components/Loading/Loading'
import { type CreateTokenModalProps } from './types'
import ViewToken from '../ViewToken/ViewToken'
import {MultiSelectCards} from "./MultiSelect";

interface TInputs {
  name: string
  linked_card_number: string[]
}
const CreateToken = (props: CreateTokenModalProps) => {
  const { active, setActive } = props
  const formMethods = useForm<TInputs>({
    mode: 'onChange',
    shouldUnregister: false,
  })
  const {
    handleSubmit,
    formState: { isValid },
    reset,
      watch
  } = formMethods
  const [activeView, setActiveView] = useState<boolean>(false)
  const [deviceId, setDeviceId] = useState<string>('')
  const [cardsCreate, setCardsCreate] = useState([])
  const watchNameToken = watch('name')
  const [createToken, { isLoading }] = useCreateDeviceMutation()
  const onSubmit: SubmitHandler<TInputs> = async (data: TInputs) => {
    const newData = {
      name: data.name,
      linked_card_number: cardsCreate
    }
    if (cardsCreate.length !== 0 && watchNameToken) {
      await createToken(newData)
          .then((response) => {
            // @ts-expect-error
            setDeviceId(response.data.id)
            setActive(false)
            setActiveView(true)
            reset()
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <FormProvider {...formMethods}>
      <ModalDefault
        title={'Создание API-токена'}
        titleMargin={'24px'}
        active={active}
        setActive={setActive}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledInput
            title={'Имя устройства'}
            rules={{ required: true, minLength: 1 }}
            blockStyle={{ marginBottom: '16px' }}
            placeholder={'Введите имя устройства'}
            name={'name'}
            type={'text'}
            required={true}
          />
          <MultiSelectCards setCardsCreate={setCardsCreate} />
          <Button text={'Создать'} disabled={!watchNameToken || cardsCreate.length === 0} type={'submit'} />
        </form>
      </ModalDefault>
      <ViewToken deviceId={deviceId} active={activeView} setActive={setActiveView} />
    </FormProvider>
  )
}

export default CreateToken
