import { type ViewTokenModalProps } from './types'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button } from '../../../../../../components/Button/Button'
import { ModalDefault } from '../../../../../../components/ModalDefault/ModalDefault'
import { useGetDeviceQuery, useGetQrQuery } from '../../../../../../redux/services/DevicesApi'
import { Loading } from '../../../../../../components/Loading/Loading'
import styles from './ViewToken.module.css'

const ViewToken = (props: ViewTokenModalProps) => {
  const { deviceId, active, setActive } = props
  const [viewType, setViewType] = useState<'qr' | 'token'>('token')
  const [isCopyCardVisible, setCopyAlertVisible] = useState<boolean>(false)
  const { data: tokenData, isLoading: isTokenLoading } = useGetDeviceQuery(deviceId, {
    skip: deviceId === '',
  })
  const { data: qrData, isLoading: isQrLoading } = useGetQrQuery(deviceId, {
    skip: deviceId === '',
  })
  const handleSwitchModalType = (type: 'qr' | 'token') => {
    setViewType(type)
  }
  const handleCopyCardClick = () => {
    setCopyAlertVisible(true)
    setTimeout(() => {
      setCopyAlertVisible(false)
    }, 2000)
  }
  if (isQrLoading || isTokenLoading) {
    return <Loading />
  }
  return (
    <ModalDefault
      title={'Создание API-токена'}
      titleMargin={'8px'}
      active={active}
      setActive={setActive}
    >
      <span className={styles.tokenStatus}>Токен успешно создан</span>
      <div className={styles.tokenContainer}>
        {viewType === 'token' ? (
          <>
            <div>
              <div className={styles.textToken}>{tokenData?.token || ''}</div>
              <CopyToClipboard text={tokenData?.token || ''}>
                <img
                  className={styles.copy}
                  src={isCopyCardVisible ? '/copy-submitted-icon.png' : '/copy_icon.png'}
                  alt={'copy'}
                  onClick={() => {
                    handleCopyCardClick()
                  }}
                />
              </CopyToClipboard>
            </div>
            <Button
              text={'Показать QR-код'}
              action={() => {
                handleSwitchModalType('qr')
              }}
              filled={false}
            />
          </>
        ) : (
          <>
            <div>
              <img className={styles.token} src={qrData} alt={'qr'} />
            </div>
            <Button
              text={'Показать текст'}
              action={() => {
                handleSwitchModalType('token')
              }}
              filled={false}
            />
          </>
        )}
      </div>
    </ModalDefault>
  )
}

export default ViewToken
