import React, { useState } from 'react'
import styles from './SwitchButton.module.css'
import { useToggleCardMutation } from '../../../../../../../../redux/services/CardsApi'
import { Button } from '../../../../../../../../components/Button/Button'
import { ModalDefault } from '../../../../../../../../components/ModalDefault/ModalDefault'

interface TSwitchButtonProps {
  active: boolean
  id: number
}
export const SwitchButton = (props: TSwitchButtonProps) => {
  const { active, id } = props
  const [toggleCard] = useToggleCardMutation()
  const [modalActive, setModalActive] = useState<boolean>(false)
  const handleClose = () => {
    setModalActive(false)
  }
  const handleOpen = () => {
    setModalActive(true)
  }
  const handleToggle = async () => {
    const body = {
      active: !active,
    }
    await toggleCard({ body, cardId: id }).unwrap().catch(() => {
      handleOpen()
    })
  }
  return (
    <>
      <label className={styles.switch}>
        <input
          type='checkbox'
          checked={active}
          onChange={async () => {
            await handleToggle()
          }}
        />
        <span className={`${styles.slider} ${styles.round}`}></span>
      </label>
      <ModalDefault title={'Ошибка'} active={modalActive} setActive={setModalActive} titleMargin={'8px'}>
        <div className={styles.wrapper}>
          Вы не можете добавить реквизиты данной карты, она была заблокирована админом системы.
          <p>
            <Button text={'Ок'} action={handleClose} />
          </p>
        </div>
      </ModalDefault>
    </>
  )
}
