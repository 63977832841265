import styled from 'styled-components';

export const TableWrapperStyles = styled.div`
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;
padding-bottom: 15px;
`


export const StyledTable = styled.table`
width: 100%;
min-width: 1000px;
table-layout: auto;
border-collapse: separate;
border-spacing: 0;
border: 1px solid #e2e5f0;
border-radius: 8px;
overflow: hidden;


th, td {
  border-right: 1px solid #e2e5f0;
  border-bottom: 1px solid #e2e5f0;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}

th:last-child,
  td:last-child {
  border-right: none;
}

tr:last-child td {
  border-bottom: none;
}

thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

thead tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
`

export const PaginationStyles = styled.div`
margin-top: 32px;
`
