import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import {
  type IGetOrdersRequest,
  type IOrdersResponse,
  type TOrderUpdateBody,
  type TOrderUpdateStatusBody,
} from '../types/OrdersTypes'

export const OrdersApi = createApi({
  reducerPath: 'OrdersApi',
  tagTypes: ['Orders'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl,
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getOrders: build.query<IOrdersResponse, IGetOrdersRequest>({
      query: (params) => (
        {
        params: {
          [(params?.status || '').length > 3 ? 'status__in' : 'status' ]: params?.status || '',
          page_size: params?.page_size || undefined,
          page: params?.page || undefined,
          id: params?.id || undefined
        },
        url: '/orders/',
        method: 'GET',
      }
      ),
      providesTags: ['Orders'],
    }),
    updateOrderStatus: build.mutation({
      query: (options: { body: TOrderUpdateStatusBody; id: string }) => {
        const { body, id } = options
        return { url: `/users/change_order_status/${id}/`, method: 'PATCH', body }
      },
      invalidatesTags: ['Orders'],
    }),
    updateOrderData: build.mutation({
      query: (options: { body: TOrderUpdateBody; id: string }) => ({
        url: `/users/change_order_data/${options.id}/`,
        method: 'PATCH',
        body: options.body,
      }),
      invalidatesTags: ['Orders'],
    }),
  }),
})

export const { useGetOrdersQuery, useUpdateOrderStatusMutation, useUpdateOrderDataMutation } =
  OrdersApi
