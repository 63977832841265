import { recoilPersist } from 'recoil-persist'
import { atom } from 'recoil'

const { persistAtom: persistAtomRegister } = recoilPersist({
  key: 'isMobile',
  storage: localStorage,
  converter: JSON,
})
export const defaultIsMobile = atom({
  key: 'isMobile',
  default: {
    isMobile: false,
  },
  effects_UNSTABLE: [persistAtomRegister],
})
