import {useEffect, useState} from 'react'
import { useAddCardMutation } from '../../../../../redux/services/CardsApi'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useGetBanksQuery } from '../../../../../redux/services/BanksApi'
import { Button } from '../../../../../components/Button/Button'
import { Loading } from '../../../../../components/Loading/Loading'
import { ModalDefault } from '../../../../../components/ModalDefault/ModalDefault'
import { ControlledInput } from '../../../../../components/ControlledInput/ControlledInput'
import { Selector, type TItem } from '../../../../../components/Selector/Selector'
import { parseStringToNumber } from '../../../../../utils/functions/parseNumber'
import { type TBanksModel } from '../../../../../redux/types/BanksTypes'
import styles from './styles.module.css'
import { IInputs } from './types';
import { paymentNotificationTypeOptions, paymentTypeOptions, transformFunction } from './utils';

export const AddCard = () => {
  const formMethods = useForm<IInputs>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const { handleSubmit, setError, reset, watch } = formMethods
  const [activeModal, setActiveModal] = useState<boolean>(false)
  const [cardExist, setCardExist] = useState<boolean>(false)
  const [addCard] = useAddCardMutation()
  const watchMethod = watch('payment_type')
  const payment_notification_type = watch('payment_notification_type')
  const { data: banks, isLoading } = useGetBanksQuery({ payment_notification_type }, {skip: !payment_notification_type})
  const onSubmit = async (data: IInputs) => {
    if (!data.bankName) {
      setError('bankName', { type: 'manual', message: 'You need to select a bank' })
    } else {
      const newCard = {
        account_number: data.cardNumber ? data.cardNumber : data.accountNumber,
        tag: data.tag,
        payment_type: data.payment_type,
        active: true,
        phone_number: data.phone_number,
        total_limit: parseStringToNumber(data.limit),
        fio: data.name,
        description: data.description,
        min_income: Number(data.min_income) ?? 0,
        max_income: Number(data.max_income) ?? 0,
        instant_active_orders: Number(data.instant_active_orders) ?? 0,
        bank: Number(
            banks?.results.find((bank) => {
              if (data.bankName === bank.name) {
                return bank
              }
            })!.id,
        ),
      }
      await addCard(newCard)
          .unwrap()
          .then(() => {
            setActiveModal(false)
            reset()
          })
          .catch((error) => {
            if (error.status === 400) {
              setActiveModal(false)
              setCardExist(true)
            }
          })
    }
  }

    useEffect(() => {
        switch (watchMethod) {
            case 'SBP':
                formMethods.resetField('cardNumber')
                formMethods.resetField('accountNumber')
                break
            case 'C2C':
                console.log('clean')
                formMethods.resetField('accountNumber')
                formMethods.setValue('phone_number', '')
                break
            case 'TRANSFER':
                formMethods.resetField('cardNumber')
                formMethods.resetField('phone_number')
        }
    }, [watchMethod]);
  if (isLoading) {
    return <Loading />
  }
  return (
      <FormProvider {...formMethods}>
        <div>
          <Button
              text={'Добавить реквизиты'}
              type={'button'}
              action={() => {
                setActiveModal(true)
              }}
              style={{ position: 'absolute', right: '0', top: '0' }}
          />
          <ModalDefault
              title={'Новые реквизиты'}
              titleMargin={'12px'}
              active={activeModal}
              setActive={setActiveModal}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControlledInput
                title={'Название реквизита (тег)'}
                required={true}
                placeholder={'Введите название'}
                name={'tag'}
                type={'text'}
                blockStyle={{marginBottom: '8px'}}
              />
              <div style={{display: 'flex', width: '100%', gap: '8px', marginBottom: '-10px'}}>
                <Selector
                  title={'Метод оплаты'}
                  placeholder={'Выберите метод оплаты'}
                  required={true}
                  name={'payment_type'}
                  items={paymentTypeOptions}
                  blockStyle={{marginBottom: '16px', width: '100%'}}
                />
                  <Selector
                      title={'Тип сообщений'}
                      placeholder={'Выберите тип'}
                      required={true}
                      name={'payment_notification_type'}
                      items={paymentNotificationTypeOptions}
                      blockStyle={{marginBottom: '16px', width: '100%'}}
                  />
              </div>
                <ControlledInput
                    title={'ФИО'}
                    placeholder={'Введите ФИО'}
                    name={'name'}
                    type={'text'}
                    required={true}
                    rules={{required: true}}
                    blockStyle={{marginBottom: '12px'}}
                    transform={(text?: string) => {
                        if (text) {
                            return text.slice(0, 30)
                        }
                        return text
                    }}
                />
                <div style={{ display: 'flex', width: '100%', gap: '8px', marginBottom: '-10px'}}>
                    <Selector
                        title={'Наименование банка'}
                        name={'bankName'}
                        placeholder={'Выберите банк'}
                        required={true}
                        blockStyle={{marginBottom: '16px', width: '100%'}}
                        items={
                            banks?.results.map((bank: TBanksModel): TItem => {
                                return {
                                    name: bank.name,
                                    id: String(bank.id),
                                }
                            }) || []
                        }
                    />
                    <ControlledInput
                        title={'Номер карты'}
                        placeholder={'XXXX XXXX XXXX XXXX'}
                        name={'cardNumber'}
                        type={'text'}
                        disabled={watchMethod !== 'C2C' || !watchMethod}
                        required={watchMethod === 'C2C' && !!watchMethod}
                        rules={{required: watchMethod === 'C2C', maxLength: 19}}
                        blockStyle={{marginBottom: '16px', width: '100%'}}
                        transform={(text: string | undefined) => {
                            if (text) {
                                return text
                                    .replace(/\D/g, '')
                                    .replace(/(\d{4})/g, '$1 ')
                                    .trim()
                                    .slice(0, 19)
                            }
                            return text
                        }}
                        calculateNewPosition={(currentPosition: number, newPosition: number) => {
                            if (currentPosition === 5 || currentPosition === 10 || currentPosition === 15) {
                                return newPosition + 1
                            }
                            return newPosition
                        }}
                    />
                </div>
                <div style={{display: 'flex', width: '100%', gap: '8px', marginBottom: '10px'}}>
                    <div style={{flex: 1}}>
                        <ControlledInput
                            title={'Номер счёта'}
                            placeholder={'Введите номер счёта'}
                            name={'accountNumber'}
                            type={'text'}
                            disabled={watchMethod !== 'TRANSFER' || !watchMethod}
                            required={watchMethod === 'TRANSFER' && !!watchMethod}
                            blockStyle={{width: '100%'}}
                            transform={(text: string | undefined) => {
                              if (text) {
                                return text.replace(/[^0-9]/g, '')
                              }
                              return text
                            }}
                        />
                    </div>
                    <div style={{flex: 1}}>
                        <Controller
                            rules={{
                                required: watchMethod === 'SBP',
                                minLength: 12,
                                maxLength: 12
                            }}
                            render={({field: {onChange, ref, value}}) => (
                                <>
                                    <label style={{fontSize: '14px'}}>
                                        {watchMethod === 'SBP' && <span style={{color: 'red'}}>*</span>} Номер телефона
                                    </label>
                                    <input
                                        type={'text'}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                if (!String(e.target.value).startsWith('+')) {
                                                    onChange('+' + e.target.value)
                                                } else {
                                                    onChange(e.target.value)
                                                }
                                            } else {
                                                onChange(e.target.value)
                                            }
                                            if (e.target.value.length >= 12) {
                                                onChange(e.target.value.slice(0, 12))
                                            }
                                        }}
                                        ref={ref}
                                        value={value}
                                        className={styles.myInput}
                                        placeholder={'Введите номер телефона'}
                                        disabled={watchMethod !== 'SBP'}
                                        style={{
                                            height: '36px',
                                            width: '100%',
                                            borderRadius: '2px',
                                            border: 'none',
                                            outline: '2px solid #dbe0f2',
                                            margin: '4px 0 0',
                                            padding: '12px',
                                            background: watchMethod !== 'SBP' ? '#E2E5F0' : 'white'
                                        }}
                                    />
                                </>
                            )}
                            name={'phone_number'}
                        />
                    </div>
                </div>
                <div style={{display: 'flex', width: '100%', gap: '8px'}}>
                    <ControlledInput
                        title={'Общий лимит'}
                        placeholder={'0'}
                        name={'limit'}
                        type={'text'}
                        required
                        rules={{required: true, maxLength: 13}}
                        blockStyle={{marginBottom: '12px', width: '100%'}}
                        transform={(text: string | undefined) => {
                            if (text) {
                                return text
                                    .replace(/[^0-9]/g, '')
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
                                    .slice(0, 13)
                            }
                            return text
                        }}
                        calculateNewPosition={(currentPosition: number, newPosition: number, valueLen: number, value) => {
                            if (valueLen - 1 === currentPosition || valueLen === currentPosition) {
                                return valueLen
                            }
                            const extra = value.replaceAll(' ', '').length % 3
                            const positions: number[] = [extra > 0 ? extra + 1 : 4]
                            for (let i = 0; i < 4; i++) {
                                positions.push(positions[i] + 4)
                            }
                            if (positions.includes(currentPosition)) {
                                return newPosition + 1
                            }
                            return newPosition
                        }}
                    />
                </div>
                <div style={{display: 'flex', width: '100%', gap: '8px'}}>
                    <ControlledInput
                        title={'Минимальная сумма платежа'}
                        placeholder={'0'}
                        name={'min_income'}
                        type={'text'}
                        blockStyle={{marginBottom: '8px', width: '100%'}}
                        transform={transformFunction}
                    />
                    <ControlledInput
                        title={'Максимальная сумма платежа'}
                        placeholder={'0'}
                        name={'max_income'}
                        type={'text'}
                        blockStyle={{marginBottom: '8px', width: '100%'}}
                        transform={transformFunction}
                    />
                </div>
                <ControlledInput
                    title={'Заявок одновременно не более'}
                    placeholder={'0'}
                    name={'instant_active_orders'}
                    type={'text'}
                    blockStyle={{marginBottom: '8px'}}
                    transform={(text: string | undefined) => {
                        if (text) {
                            return text.replace(/[^0-9]/g, '')
                        }
                        return text
                    }}
                />
                <ControlledInput
                    title={'Описание'}
                    placeholder={'Введите описание'}
                    name={'description'}
                    type={'text'}
                    blockStyle={{marginBottom: '8px'}}
                />
                <Button text={'Добавить'} type={'submit'} disabled={false}/>
            </form>
          </ModalDefault>

          <ModalDefault
            title={'Ошибка'}
              titleMargin={'8px'}
              textMargin={'24px'}
              active={cardExist}
              setActive={setCardExist}
              text={'Эта карта уже была добавлена. Хотите добавить новую?'}
          >
            <Button
                action={() => {
                  setCardExist(false)
                }}
                text={'Отмена'}
                filled={false}
                style={{ marginRight: '8px' }}
            />
            <Button
                action={() => {
                  setCardExist(false)
                  setActiveModal(true)
                }}
                text={'Добавить новую'}
            />
          </ModalDefault>
        </div>
      </FormProvider>
  )
}
