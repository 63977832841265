import React from 'react'
import { useRecoilState } from 'recoil'
import { OrderBlocks } from './components/Blocks/OrderBlocks'
import { OrderTable } from './components/Table/OrderTable'
import { defaultIsMobile } from '../../../../../../utils/states/isMobile.state'

export const Content = () => {
  const [version] = useRecoilState(defaultIsMobile)
  const isMobile = version.isMobile
  return <div>{(isMobile) ? <OrderBlocks /> : <OrderTable />}</div>
}
