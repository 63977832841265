import { useEffect, type RefObject } from 'react'

type Event = MouseEvent | TouchEvent

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current
      if (!el || el.contains(event.target as Node)) {
        return
      }
        handler(event)
    };

    document.addEventListener(mouseEvent, listener)

    return () => {
      document.removeEventListener(mouseEvent, listener)
    }
  }, [ref, handler, mouseEvent])
}
