import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import { IDisputeResponseById } from '../types/DisputeTypes';

export const DisputesApi = createApi({
  reducerPath: 'DisputesApi',
  tagTypes: ['Disputes'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl,
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (builder) => ({
    getDisputeById: builder.query<IDisputeResponseById, string>({
      query: (orderId: string) => ({
        url: `disputes/get_disputes_by_order/${orderId}`,
        method: 'GET'
      }),
      providesTags: ['Disputes'],
    }),
    postDispute: builder.mutation<any, any>({
      query: (body) => ({
        url: `disputes/`,
        method: 'POST',
        body,
        headers: {}
      })
    }),
  }),
})

export const {
  useGetDisputeByIdQuery,
  usePostDisputeMutation
} = DisputesApi
