import React from 'react'
import { useRecoilState } from 'recoil'
import { defaultIsMobile } from '../../../../../../utils/states/isMobile.state'
import WithdrawalsBlocks from './components/Block/WithdrawalsBlocks'
import WithdrawalsTable from './components/Table/WithdrawalsTable'

export const Content = () => {
  const [version] = useRecoilState(defaultIsMobile)
  const isMobile = version.isMobile
  return <div>
      {(isMobile) ? <WithdrawalsBlocks /> : <WithdrawalsTable />}
      </div>
}

