import React, { useState } from 'react'
import { CardsBlocks } from './components/CardsBlocks/CardsBlocks'
import { CardsTable } from './components/CardsTable/CardsTable'
import { useRecoilState } from 'recoil'
import { defaultIsMobile } from '../../../../../utils/states/isMobile.state'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'
import { type IInputsEditCard } from './components/components/ChangeDescriptionInput/ChangeDescriptionInput'
import { parseStringToNumber } from '../../../../../utils/functions/parseNumber'
import { useEditCardMutation } from '../../../../../redux/services/CardsApi'

export interface TCardsContentProps {
  currentId: null | number
  setCurrentId: React.Dispatch<React.SetStateAction<null | number>>
}

export const CardsContent = () => {
  const [version] = useRecoilState(defaultIsMobile)
  const [currentId, setCurrentId] = useState<number | null>(null)
  const formMethods = useForm<IInputsEditCard>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const { handleSubmit, reset } = formMethods
  const [updateCard] = useEditCardMutation()
  const handleEdit: SubmitHandler<IInputsEditCard> = async (data) => {
    const body = {
      total_limit: parseStringToNumber(data.newLimit),
      description: data.newDescription,
      min_income: data.newMinIncome,
      max_income: data.newMaxIncome,
      instant_active_orders: data.newInstantOrders
    }
    await updateCard({ cardId: Number(currentId), body })
      .unwrap()
      .then(() => {
        setCurrentId(null)
        reset()
      })
      .catch()
  }
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleEdit)}>
        {version.isMobile ? (
          <CardsBlocks currentId={currentId} setCurrentId={setCurrentId} />
        ) : (
          <CardsTable currentId={currentId} setCurrentId={setCurrentId} />
        )}
      </form>
    </FormProvider>
  )
}
