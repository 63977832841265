import styles from './Actions.module.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button } from '../../../../../../../../components/Button/Button'
import { ModalDefault } from '../../../../../../../../components/ModalDefault/ModalDefault'
import React, { useState } from 'react'
import { useGetDeviceQuery, useGetQrQuery } from '../../../../../../../../redux/services/DevicesApi'
import { Loading } from '../../../../../../../../components/Loading/Loading'
import { type TModalProps } from './types'
const ModalQr = (props: TModalProps) => {
  const { deviceId, active, setActive } = props
  const [viewType, setViewType] = useState<'qr' | 'token'>('token')
  const [isCopyCardVisible, setCopyAlertVisible] = useState<boolean>(false)
  const { data: tokenData, isLoading: isTokenLoading } = useGetDeviceQuery(deviceId, {
    skip: deviceId === '',
  })
  const { data: qrData, isLoading: isQrLoading } = useGetQrQuery(deviceId, {
    skip: deviceId === '',
  })
  const handleSwitchModalType = (type: 'qr' | 'token') => {
    setViewType(type)
  }
  const handleCopyCardClick = () => {
    setCopyAlertVisible(true)
    setTimeout(() => {
      setCopyAlertVisible(false)
    }, 2000)
  }
  if (isQrLoading || isTokenLoading) {
    return <Loading />
  }
  return (
    <ModalDefault title={'API-токен'} titleMargin={'8px'} active={active} setActive={setActive}>
      <div className={styles.container}>
        <span className={styles.status}>
          Используется для {tokenData?.name}, {tokenData?.model}
        </span>
        <div className={styles.content}>
          {viewType === 'token' ? (
            <>
              <div className={styles.token}>
                <div className={styles.textToken}>{tokenData?.token || ''}</div>
                <CopyToClipboard text={tokenData?.token || ''}>
                  <img
                    className={styles.copy}
                    src={isCopyCardVisible ? '/copy-submitted-icon.png' : '/copy_icon.png'}
                    alt={'copy'}
                    onClick={() => {
                      handleCopyCardClick()
                    }}
                  />
                </CopyToClipboard>
              </div>
              <Button
                text={'Показать QR-код'}
                action={() => {
                  handleSwitchModalType('qr')
                }}
                filled={false}
              />
            </>
          ) : (
            <>
              <div>
                <img className={styles.token} src={qrData} alt={'qr'} />
              </div>
              <Button
                text={'Показать текст'}
                action={() => {
                  handleSwitchModalType('token')
                }}
                filled={false}
              />
            </>
          )}
        </div>
      </div>
    </ModalDefault>
  )
}

export default ModalQr
