import React, {memo} from "react";
import {useGetDisputeByIdQuery} from "../../../../../../redux/services/DisputesApi";
import styles from "./components/content.module.css";
import {Loading} from "../../../../../../components/Loading/Loading";
import {mediaUrl} from "../../../../../../utils/constants/url";

interface Props {
    orderUid: any
}
export const PureModal = memo(function PureModal(props: Props) {
    const { orderUid} = props
    const { data: dispute, isLoading: isDisputeLoading } = useGetDisputeByIdQuery(orderUid, {
        skip: orderUid === ''
    })
    if (isDisputeLoading) return <Loading/>
    return (
      <div style={{maxHeight: '700px', overflow: 'scroll'}}>
          {dispute && dispute.files_data.map((item, index) => {
              const itemSplit = item.split('.')
              return (
                <div style={{marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
                    {itemSplit[itemSplit.length - 1] === 'pdf' ?
                      <iframe src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${mediaUrl}${item}`}
                              frameBorder="0"
                              height="600px" width="100%"></iframe> :
                      <img src={`${mediaUrl}${item}`}
                           key={index} className={styles.checkImage}
                           alt={'check'}/>}
                </div>
              )
          })}
        {dispute?.comment &&
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '12px' }}>
        <strong>
          Комментарий:
        </strong>
          <div>
              {dispute.comment}
          </div>
        </div>}
      </div>
    )
})