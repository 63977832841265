import { type CardsModel } from '../../types'
import { type TCards } from '../../../redux/types/CardsTypes'

const mapCards = (cards: TCards[] | undefined): CardsModel[] | undefined => {
  return cards?.map((card: any) => {
    const limit = (card.total_limit + '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return { ...card, total_limit: limit }
  })
}

export default mapCards
