import styles from './Button.module.css'
import React from 'react'

interface TButtonProps {
  text: string
  action?: () => void
  style?: React.CSSProperties
  disabled?: boolean
  type?: 'button' | 'submit'
  filled?: boolean
  id?: string
}

export const Button = (props: TButtonProps) => {
  const { text, action, style, disabled, type, filled = true, id = '' } = props
  const getClass = () => {
    if (disabled) {
      return `${styles.button} ${styles.disabled}`
    } else if (filled) {
      return styles.button
    } else {
      return `${styles.button} ${styles.notFilled}`
    }
  }
  return (
    <button
      id={id}
      className={getClass()}
      style={style}
      type={type}
      onClick={action}
      disabled={disabled}
    >
      {text}
    </button>
  )
}
