import React from 'react'
import './ChangeLimitInput.css'
import { type TEditCardProps } from '../ChangeDescriptionInput/ChangeDescriptionInput'
import { ControlledInput } from '../../../../../../../../components/ControlledInput/ControlledInput'

export const ChangeLimitInput = (props: TEditCardProps) => {
  const { id, currentId, defaultValue, name, placeholder } = props

  return (
    <>
      {id !== null && currentId !== null && id === currentId ? (
        <ControlledInput
          inputStyle={{
            padding: '4px 6px',
            color: '#2c2e35',
            border: '1px solid #1890ff',
            borderRadius: '2px',
          }}
          defaultValue={defaultValue}
          placeholder={placeholder}
          name={name}
          type={'text'}
          rules={{ required: true, maxLength: 13 }}
          transform={(text: string | undefined) => {
            if (text) {
              return text
                .replace(/[^0-9]/g, '')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
                .slice(0, 13)
            }
            return text
          }}
        />
      ) : (
        defaultValue
      )}
    </>
  )
}
