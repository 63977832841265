import { type DeviceNameProps } from './types'
import styles from './DeviceName.module.css'

const DeviceName = (props: DeviceNameProps) => {
  const { name, model, date } = props
  return (
    <div className={styles.deviceInfo}>
      <p>{name}</p>
      <p>{model}</p>
      <span>{date}</span>
    </div>
  )
}

export default DeviceName
