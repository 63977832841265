export const listHiddenHeader = [
  '/auth',
  '/auth/',
  '',
  '/',
  '/replenishment',
  '/replenishment/',
  '/transfer',
  '/transfer/',
  '/success-transfer',
  '/success-transfer/',
  '/fail-transfer',
  '/fail-transfer/',
]
