import './Blocks.css'
import React from 'react'
import { type BlocksProps } from '../../utils/interfaces'

export const Blocks: React.FC<BlocksProps> = ({ blocks }) => {
  if (blocks.length === 0) {
    return (
      <div className={'no-data-icon'}>
        <img src={'/no_data_icon.png'} alt={'no data icon'} />
      </div>
    )
  }
  return (
    <div className={'info-block'}>
      {blocks.map((element) => {
        return element
      })}
    </div>
  )
}
