import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import type IBaseRequest from '../types/Base/BaseRequestInterface'
import {
  type TCardsResponse,
  type TCreateCardRequest,
  type TToggleCardRequest,
  type TToggleCardsRequest,
  type TUpdateCardRequest,
} from '../types/CardsTypes'

export const CardsApi = createApi({
  reducerPath: 'CardsApi',
  tagTypes: ['Cards'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl,
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getCards: build.query<TCardsResponse, IBaseRequest>({
      query: (params) => ({
        url: 'requisite/',
        params: { page: params?.page || 1, page_size: params?.page_size || 0 },
        method: 'GET',
      }),
      providesTags: [{ type: 'Cards', id: 'LIST' }],
    }),
    addCard: build.mutation<void, TCreateCardRequest>({
      query: (body) => ({
        url: 'requisite/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Cards', id: 'LIST' }],
    }),
    toggleAllCards: build.mutation<void, TToggleCardsRequest>({
      query: (body) => ({
        url: 'requisite/toggle_all_requisites/',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'Cards', id: 'LIST' }],
    }),
    toggleCard: build.mutation<void, TToggleCardRequest>({
      query: (params) => ({
        url: `requisite/${params.cardId}/`,
        method: 'PATCH',
        body: params.body,
      }),
      invalidatesTags: [{ type: 'Cards', id: 'LIST' }],
    }),
    editCard: build.mutation<void, TUpdateCardRequest>({
      query: (params) => ({
        url: `/requisite/${params.cardId}/`,
        method: 'PATCH',
        body: params.body,
      }),
      invalidatesTags: [{ type: 'Cards', id: 'LIST' }],
    }),
    deleteCard: build.mutation<void, number>({
      query: (params) => ({
        url: `/requisite/${params}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Cards', id: 'LIST' }],
    }),
    getAllCards: build.query<TCardsResponse, IBaseRequest>({
      query: (params) => ({
        url: 'requisite/',
        method: 'GET'
      }),
      providesTags: [{ type: 'Cards', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetCardsQuery,
  useAddCardMutation,
  useToggleAllCardsMutation,
  useToggleCardMutation,
  useEditCardMutation,
  useDeleteCardMutation,
  useGetAllCardsQuery
} = CardsApi
