import styled from 'styled-components';

export const SearchContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 440px;
  `;

export const SearchLabel = styled.div`
    display: flex;
    width: 70%;
    margin-bottom: 12px;
    align-items: center;
`;

export const Input = styled.input`
    width: 100%;
    margin-bottom: 12px;
    align-items: center;
    height: 40px;
`;

