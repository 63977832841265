import { useRecoilState } from 'recoil'
import { defaultActiveOrdersFilter } from '../../../../../../../../utils/states/activeFilter.state'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useGetOrdersQuery, useUpdateOrderStatusMutation } from '../../../../../../../../redux/services/OrdersApi'
import calculateElements from '../../../../../../../../utils/functions/calcucateElements'
import { ordersThArray } from '../../../../../../../../utils/constants/th_arrays'
import mapOrders, { OrderModel } from '../../../../../../../../utils/functions/map/mapOrders'
import { Table } from '../../../../../../../../components/Table/Table'
import Income from '../../../../../components/Income/Income'
import { Selector } from '../../../../../components/Selector/Selector'
import { Pagination } from '../../../../../../../../components/Pagination/Pagination'
import { ModalDefault } from "../../../../../../../../components/ModalDefault/ModalDefault";
import {PureModal} from "../../PureModal";
import {pushNotify} from "../../../../../../../../components/Notifications";

import * as UI from './styles'

const getStyle = (baseStyle: CSSProperties): CSSProperties => ({
  ...baseStyle,
  padding: baseStyle.padding || '0',
  wordBreak: 'normal' as CSSProperties['wordBreak'],
})

export const OrderTable = () => {
  const [{ activeOrdersFilter }] = useRecoilState(defaultActiveOrdersFilter);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(6);
  const [openSelector, setOpenSelector] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [orderUid, setOrderUid] = useState<string>('');
  const [pollingInterval, setPollingInterval] = useState(5000);
  const [searchId, setSearchId] = useState<string>();
  const [prevCount, setPrevCount] = useState<number | null | undefined>();
  const { data } = useGetOrdersQuery(
      { status: activeOrdersFilter, page, page_size: pageSize, id: searchId?.trim() || '' },
      { pollingInterval }
  );

  const { data: disputes, isSuccess: isDisputesSuccess } = useGetOrdersQuery(
      { status: 'ID', page: undefined, page_size: undefined },
      { pollingInterval: 5000 }
  );
  const [updateOrder, { isLoading }] = useUpdateOrderStatusMutation();

  const handleEdit = async (newStatus: string, id: string) => {
    const body = { status: newStatus };
    await updateOrder({ body, id }).unwrap()
  };

  const handleResize = () => {
    setPageSize(calculateElements([720, 760, 820, 870, 940, 990], 6));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setPage(1);
  }, [activeOrdersFilter]);

  useEffect(() => {
    if (disputes && prevCount === undefined) {
      setPrevCount(disputes.count);
    }
  }, [disputes, prevCount]);

  useEffect(() => {
    if (disputes && prevCount !== null && prevCount !== undefined) {
      if (disputes.count > prevCount) {
        pushNotify(`НОВЫЙ СПОР`, 'ВНИМАНИЕ, НОВЫЙ СПОР');
        setPrevCount(disputes.count);
      }
      setPrevCount(disputes.count)
    }
  }, [disputes]);

  useEffect(() => {
    if (data?.results.some(order => order.time_left > 0)) {
      setPollingInterval(1000);
    } else {
      setPollingInterval(5000);
    }
  }, [data]);

  const getOrderStyle = (statusId: string): CSSProperties => {
    switch (statusId) {
      case 'BN':
        return { background: '#F3FFF4'};
      case 'ID':
        return { background: 'rgba(246, 86, 86, 0.41)'};
      case 'AAD':
        return { background: 'rgba(203, 202, 202, 041)'};
      default:
        return {}
    }
  }

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const renderOrderRow = (order: OrderModel) => {
    const style = getOrderStyle(order.statusId);
    const linkStyle: CSSProperties = { cursor: 'pointer', color: 'blue', textDecoration: 'underline'};

    return [
      {
        element: order.statusId === 'ID' ? (
          <a
            style={linkStyle}
            onClick={() => {
              setOpenModal(true);
              setOrderUid(order.id)
            }}
            >
            {order.id}
          </a>
        ) : order.id,
        style,
      },
      {
        element: (
          <Selector
            id={order.id}
            statusId={order.statusId}
            status={order.status}
            handleEdit={handleEdit}
            open={openSelector}
            setOpen={setOpenSelector}
            needRecalculation
            />
        ),
        style: getStyle(style)
      },
      { element: <div>{order.fio} {order.card_number}</div>, style },
      { element: order.bank, style },
      { element: order.expected_income, style },
      {
        element: order.statusId === 'ID' ? (
          <>
            {order.temp_income}
            <Income order={order} />
          </>
        ) : (
          <Income order={order} />
        ),
        style,
      },
      {
        element: order.date,
        style: getStyle({ ...style, padding: '10px'})
      },
      { element: order.time_left ? formatTime(order.time_left) : '', style },
    ];
  };

  return (
    <div>
      <UI.SearchContainer>
        <UI.SearchLabel>Найдите заявку</UI.SearchLabel>
        <UI.Input
          type="text"
          value={searchId}
          onChange={(event) => setSearchId(event.target.value)}
        />
      </UI.SearchContainer>
      <ModalDefault
        title="Чек по операции"
        active={openModal}
        setActive={setOpenModal}
        key="modalCheck"
      >
        <PureModal orderUid={orderUid} />
      </ModalDefault>
      <Table
        thArray={ordersThArray}
        tdArray={mapOrders(data?.results)?.map(renderOrderRow) || []}
      />
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        currentPage={page}
        setCurrentPage={setPage}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </div>
  )
}
