import SettingsTable from './components/SettingsTable/SettingsTable'
import { useRecoilState } from 'recoil'
import { defaultIsMobile } from '../../../../../utils/states/isMobile.state'
import SettingsBlocks from './components/SettingsBlocks/SettingsBlocks'

const SettingsContent = () => {
  const [version] = useRecoilState(defaultIsMobile)
  return <>{version.isMobile ? <SettingsBlocks /> : <SettingsTable />}</>
}

export default SettingsContent
