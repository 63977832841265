import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { listHiddenHeader } from '../constants/hidden_urls_navbar'

export const useVisible = () => {
  const { pathname } = useLocation()
  const notVisible = useMemo(() => {
    return listHiddenHeader.includes(pathname) || sessionStorage.getItem('token') === null
  }, [pathname])
  return { notVisible }
}
