export const transformFunction = (text: string | undefined) => {
  if (text) {
    let formattedText = text.replace(/[^0-9.]/g, '');
    const parts = formattedText.split('.');
    if (parts.length > 2) {
      formattedText = parts[0] + '.' + parts.slice(1).join('');
    }
    if (parts.length === 2) {
      formattedText = parts[0] + '.' + parts[1].slice(0, 2);
    }
    return formattedText.trim();
  }
  return text;
};

export const paymentTypeOptions = [
  {name: 'SBP', id: 'SBP'},
  {name: 'C2C', id: 'C2C'},
  {name: 'TRANSFER', id: 'TRANSFER'}
]

export const paymentNotificationTypeOptions = [
  {name: 'SMS', id: 'SMS'},
  {name: 'PUSH', id: 'PUSH'},
]
