import React, { useState } from 'react'
import { Button } from '../../../../../../../../components/Button/Button'
import styles from './ActionButtons.module.css'
import DeleteCardModal from '../DeleteCardModal/DeleteCardModal'

interface TActionButtonProps {
  currentId: null | number
  setCurrentId: React.Dispatch<React.SetStateAction<null | number>>
  id: number
}

export const ActionButtons = (props: TActionButtonProps) => {
  const { currentId, setCurrentId, id } = props
  const [modalActive, setModalActive] = useState<boolean>(false)
  return (
    <>
      <div className={styles.wrapper}>
        {id !== null && currentId !== null && id === currentId ? (
          <>
            <Button
              id={'save'}
              text={''}
              type={'submit'}
              disabled={false}
              style={{ display: 'none' }}
            />
            <label htmlFor={'save'}>
              <img src={'/save_icon.png'} alt={'save_icon'} />
            </label>
          </>
        ) : (
          <img
            src={'/edit_icon.png'}
            alt={'edit_icon'}
            onClick={() => {
              setCurrentId(id)
            }}
          />
        )}
        <img
          src={'/delete_icon.png'}
          alt={'delete_icon'}
          onClick={() => {
            setModalActive(true)
          }}
        />
      </div>
      <DeleteCardModal id={id} modalActive={modalActive} setModalActive={setModalActive} />
    </>
  )
}
